import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Milosz = () => {
    return (
        <Layout>
            <Seo title="Milosz"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Miłosz Kaźmierczak</h1>
                        <p className="member-intro_text_description">Dyplomowany instruktor Pilates
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src="../images/poza_milosz.jpg" 
                            placeholder="blurred"
                            alt="Instruktor Miłosz Kaźmierczak" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O MIŁOSZU</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Dyplomowany instruktor Pilates, biolog</h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Miłosz pracuje metodą Pilates w nurcie współczesnym, czyli sportowo-rehabilitacyjnym.</p>
                            <p className="member-hero-paragraph_info-text">W pracy kieruje się myślą, że to "metoda Pilates jest dla człowieka, a nie człowiek dla metody". Dlatego ważne jest dla niego budowanie z każdą osobą współpracy opartej na komunikacji i zaufaniu, aby jak najlepiej odpowiedzieć na potrzeby i cele każdego klienta/klientki. Miłosz widzi Pilates jako metodę, która pozwala na ułożenie programu dla każdego, kto chce ćwiczyć, niezależnie od tego, jak na dany moment porusza się jego ciało. Właśnie ten inkluzywny charakter metody Pilates zaważył na decyzji o zostaniu instruktorem. Bliska jest mu także idea "body-positivity", którą podczas sesji Pilates przekłada na skupienie się na tym jak klient/klientka czuje się w swoim ciele i w ruchu, a nie na tym jak to ciało wygląda. W pracy z wszystkimi osobami stawia na rozwijanie świadomości ciała, ale także na przyjemności, radości czy spokoju, które mogą płynąć z ruchu.</p>
                            <p className="member-hero-paragraph_info-text">Miłosz przez wiele lat amatorsko zajmował się tańcem współczesnym i klasycznym. Najpierw poznał Pilates jako trening wspomagający dla tancerzy. Ukończył wiele szkoleń i warsztatów metody Pilates (m. in. pełne szkolenie dyplomowe w Akademii Diamond Pilates w Warszawie oraz roczny specjalistyczny kurs dla instruktorów „Bio-Logika w Pilatesie - Pilates Teacher Excellence Program” w Pilates terapeutyczny Studio).</p>
                            <p className="member-hero-paragraph_info-text">Uwielbia się uczyć i uważa, że dla instruktora Pilates ciągły rozwój jest niezbędny.</p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Angielski/ English</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Pilates</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Indywidualnie</li>
                                <li>W duetach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Reformer</li>
                                <li>Reformer z Wieżą</li>
                                <li>Wunda Chair oraz Combo Chair</li>
                                <li>Ladder Barrel</li>
                                <li>Cadillac</li>
                                <li>Ped-O-Pull</li>
                                <li>Spine Corrector</li>
                                <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Osoby z wadami postawy </li>
                                <li>Osoby po operacjach</li>
                                <li>Osoby z chronicznym bólem</li>
                                <li>Sportowcy</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_info_link-container">
                            <Link to="/zapisy" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</Link>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Milosz;